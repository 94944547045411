<template>
  <div class="card">
    <h2 class="card-header">
      zoomIntegrations
    </h2>
    <div class="card-body">
      <DataTable
        :fields="zoomIntegrationFields"
        :rows="currentPage"
        :total-pages="totalPages"
        :search-enabled="true"
        :load-list="getList"
        :is-loading="$isLoading('getListZoomIntegration')"
        :actions="zoomIntegrationActions"
        :empty-message="'No zoomIntegrations'"
        class="w-full"
      />

      <div class="flex flex-row-reverse mt-4">
        <button
          :disabled="$isLoading('getListZoomIntegration')"
          @click="$refs.newZoomIntegrationDialog.openDialog()"
        >
          New Zoom Integration
        </button>
      </div>
    </div>

    <NewZoomIntegrationDialog ref="newZoomIntegrationDialog" />
    <EditZoomIntegrationDialog ref="editZoomIntegrationDialog" />
    <DeleteZoomIntegrationDialog ref="deleteZoomIntegrationDialog" />
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import omit from 'lodash/omit';
import { loadable } from 'vue-is-loading';
import DataTable from '#ui/components/DataTable';
import { ZOOM_INTEGRATION_MODEL_FIELDS } from '#features/zoom-integration/lib/models/ZoomIntegrationModel';
import NewZoomIntegrationDialog from '#features/zoom-integration/ui/dialogs/NewZoomIntegrationDialog';
import EditZoomIntegrationDialog from '#features/zoom-integration/ui/dialogs/EditZoomIntegrationDialog';
import DeleteZoomIntegrationDialog from '#features/zoom-integration/ui/dialogs/DeleteZoomIntegrationDialog';

// Setup

const store = useStore();
const currentPage = computed(() => store.state.ZoomIntegrations.currentPage);
const totalPages = computed(() => store.state.ZoomIntegrations.totalPages);
const zoomIntegrationFields = computed(() => omit(ZOOM_INTEGRATION_MODEL_FIELDS, 'id'));

// List navigation

const getList = loadable(
  (values) => store.dispatch('ZoomIntegrations/getList', values),
  'getListZoomIntegration',
  getCurrentInstance(),
);

// Row actions

const editZoomIntegrationDialog = ref(null);
const openEditDialog = (zoomIntegration) => {
  editZoomIntegrationDialog.value.openDialog(zoomIntegration);
};

const deleteZoomIntegrationDialog = ref(null);
const openDeleteDialog = (zoomIntegration) => {
  deleteZoomIntegrationDialog.value.openDialog(zoomIntegration);
};

const zoomIntegrationActions = [
  { name: 'Edit Zoom Integration', fn: openEditDialog },
  { name: 'Delete Zoom Integration', fn: openDeleteDialog },
];
</script>
